<template>
  <div id="profile">
    <template v-if="progressLoading">
      <div>
        <CommonProgressLoading />
      </div>
    </template>
    <template v-else>
      <div id="profileCard">
        <div class="imgBg">
          <img src="images/profileBg.png" alt="">
        </div>
        <div class="content">
          <div class="top">
            <div class="avatarBlock mt-10">
              <v-avatar :image="userInfo.domain+profileInfo.avatar" size="90" class="mb-5" />
              <button class="editBtn">
                <i class="fa-regular fa-pen-to-square" />
                <div>編輯</div>
              </button>
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                hide-details="auto"
                class="fileInput"
                @change="uploadAvatar"
              />
            </div>
            <div class="text-h6 nameBlock" @click="showEdit(profileInfo.username)">
              <div v-if="!editUserNameBlock" class="name">
                {{ profileInfo.username }}
                <i class="fa-regular fa-pen-to-square ms-2" />
              </div>
              <!-- 編輯姓名 -->
              <div v-if="editUserNameBlock" class="editUserNameBlock">
                <v-text-field
                  v-model="editUserName"
                  variant="solo"
                  type="text"
                  density="compact"
                  clearable
                  class="editNameInput"
                  hide-details
                  @keyup.enter.stop="submitEditName()"
                />
                <button class="inputBtn ms-2 me-1" @click.stop="submitEditName()">
                  <i class="fa-solid fa-check ok" />
                </button>
                <button class="inputBtn" @click.stop="editUserNameBlock = false">
                  <i class="fa-solid fa-xmark cancel" />
                </button>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="editBlock">
              <span class="me-5"><i class="fa-regular fa-envelope" /></span>
              <div class="editMail">
                {{ profileInfo.email }}
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <span class="font-weight-bold me-5">部門</span>
            {{ profileInfo.deptName }}
          </div>
          <div class="card mb-4">
            <span class="font-weight-bold me-5">角色</span>
            {{ profileInfo.roleName }}
          </div>
        </div>
        <div class="bottom">
          <div class="editBlock mb-5">
            其他登入方式
          </div>
          <div class="levelBlock">
            <!-- Google -->
            <div>
              <img
                height="25"
                width="25"
                src="/images/googleIcon.png"
                alt="Google Icon"
                :class="{ 'grayscale clickable': !isBindGoogle }"
                @click="isBindGoogle ? '' : googleBindOnSubmit()"
              >
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                <span v-if="isBindGoogle" class="font-weight-bold">
                  <i class="fa-regular fa-circle-check" style="color: #3efc3b;" /> {{ profileInfo.googleEmail }}
                </span>
                <span v-else>
                  綁定Google帳號
                </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div id="mainSettings">
        <v-form @submit.prevent="editProfile()">
          <div class="mainContent">
            <v-row no-gutters class="h100">
              <v-col cols="7">
                <!-- 更改密碼 -->
                <div class="ps-4 pe-4">
                  <div>
                    <div class="text-h6 font-weight-bold">
                      {{ isPwdSet ? '更改密碼' : '設置密碼' }}
                    </div>
                    <div class="subTitle">
                      {{ isPwdSet ? '更改新密碼後記得再次確認新密碼' : '設置密碼後可以使用帳號密碼登錄' }}
                    </div>
                  </div>
                  <div class="mt-10">
                    {{ isPwdSet ? '舊密碼' : '' }}
                    <v-text-field
                      v-if="isPwdSet"
                      v-model="oldPwd"
                      placeholder="請輸入舊密碼"
                      :append-icon="showOldPwd ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
                      :type="showOldPwd ? 'text' : 'password'"
                      hide-details="auto"
                      density="compact"
                      variant="plain"
                      class="plainInput mb-5"
                      style="width:300px;"
                      @click:append="showOldPwd = !showOldPwd"
                    />
                    <div>
                      新密碼
                      <v-text-field
                        v-model="newPwd"
                        placeholder="請輸入新密碼"
                        hint="至少8個字母，由大小寫英文和數字及符號組合"
                        :rules="newPwdRules"
                        :append-icon="showNewPwd ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
                        :type="showNewPwd ? 'text' : 'password'"
                        hide-details="auto"
                        density="compact"
                        variant="plain"
                        class="plainInput mb-5"
                        style="width:300px;"
                        @click:append="showNewPwd = !showNewPwd"
                      />
                    </div>
                    <div>
                      確認密碼
                      <v-text-field
                        v-model="checkPwd"
                        placeholder="請再次確認密碼"
                        :rules="checkPwdRules"
                        :append-icon="showCheckPwd ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'"
                        :type="showCheckPwd ? 'text' : 'password'"
                        hide-details="auto"
                        density="compact"
                        variant="plain"
                        class="plainInput"
                        style="width:300px;"
                        @click:append="showCheckPwd = !showCheckPwd"
                      />
                    </div>
                  </div>
                </div>
                <v-divider class="mt-10 mb-10" />
                <!-- 座席聊天上限數 -->
                <div class="ps-4 pe-4">
                  <div>
                    <div class="text-h6 font-weight-bold">
                      座席聊天上限數
                    </div>
                    <div class="subTitle">
                      ex: 假設這邊設定為5，則該客服服務上限為5個對話
                    </div>
                  </div>
                  <div class="mt-10">
                    上限數
                    <v-text-field
                      v-model="profileInfo.abilityNum"
                      type="number"
                      hide-details="auto"
                      density="compact"
                      variant="plain"
                      class="plainInput"
                      style="width:300px;"
                      clearable
                    />
                  </div>
                </div>
                <v-divider class="mt-10 mb-10" />
                <!-- 個人歡迎語 -->
                <div class="ps-4 pe-4">
                  <div class="switchBtn">
                    <div class="w-100">
                      <div class="text-h6 font-weight-bold">
                        個人歡迎語
                      </div>
                      <div class="subTitle">
                        【個人歡迎語】優先【公共歡迎語】的權限內容。
                      </div>
                    </div>
                    <v-switch
                      v-model="profileInfo.helloMsgModel"
                      color="#448AFF"
                      hide-details
                      inset
                    />
                  </div>
                  <div class="mt-10">
                    <!-- TODO 富文本0.3.0 -->
                    <template v-if="profileInfo.helloMsgModel">
                      <div class="colorRed">
                        【0.2.0】富文本尚未開發
                      </div>
                      <v-text-field
                        v-model="profileInfo.helloMsg"
                        type="text"
                        hide-details="auto"
                        density="compact"
                        variant="plain"
                        class="plainInput"
                        clearable
                      />
                    </template>
                  </div>
                </div>
                <v-divider class="mt-10 mb-10" />
                <!-- 二次驗證 -->
                <div class="ps-4 pe-4">
                  <div>
                    <div class="text-h6 font-weight-bold">
                      二次驗證
                    </div>
                    <div class="subTitle">
                      【二次驗證】內容尚無。
                    </div>
                  </div>
                  <div class="mt-10">
                    <ProfileTwoFactorBindTwoFactor v-if="!isTwoFactor" />
                    <!-- 成功 -->
                    <ProfileTwoFactorUnbindTwoFactor v-else />
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- 保存 -->
          <v-btn
            :loading="loading"
            class="text-none saveSetBtn"
            color="#00BCD4"
            size="large"
            rounded="xl"
            text="保存"
            variant="flat"
            type="submit"
            style="color: #fff;"
          />
        </v-form>
      </div>
    </template>
  </div>
</template>

<script setup>
import { getProfileFn, editProfileFn, getProfileUploadUrlFn } from '../../api/auth/users'
import { getRolesFn } from '../../api/auth/roles'
import { getDeptDetailFn } from '../../api/auth/departments'
import { uploadImg } from '../../api/admin/upload'
import { checkTwoFactorFn } from '../../api/auth/twofactorAuth'
import { googleBindFn, openGoogleFn } from '../../api/auth/oauth'
const userInfo = useUserInfo()

const oldPwd = ref('') // 舊密碼
const newPwd = ref('') // 新密碼
const checkPwd = ref('') // 確認密碼

const showOldPwd = ref(false)
const showNewPwd = ref(false)
const showCheckPwd = ref(false)

// 【新密碼】【確認密碼】防呆
const newPwdRule = {
  pwd: (value) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()_\-=/\\+<> ]).{8,}$/
    return pattern.test(value) || '格式錯誤 (至少8個字母，由大小寫英文和數字及符號組合)'
  }
}
const newPwdRules = computed(() => {
  return newPwd.value ? [newPwdRule.pwd] : []
})
const checkPwdRule = {
  pwd: (value) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()_\-=/\\+<> ]).{8,}$/
    return pattern.test(value) || '格式錯誤 (至少8個字母，由大小寫英文和數字及符號組合)'
  },
  checkPwd: value => value === newPwd.value || '新密碼跟確認密碼不相同'
}
const checkPwdRules = computed(() => {
  return newPwd.value ? [checkPwdRule.pwd, checkPwdRule.checkPwd] : []
})

const profileInfo = ref({
  username: '',
  email: '',
  agentId: '',
  avatar: null,
  abilityNum: '',
  helloMsgModel: null,
  helloMsg: '',
  roleName: '',
  deptName: ''
})

// 密碼已設置
const isPwdSet = ref(true)
// 二次驗證
const isTwoFactor = ref(false)
// 綁定google帳號
const isBindGoogle = ref(false)

const loading = ref(false)

// get api loading
const progressLoading = ref(false)

onBeforeMount(() => {
  progressLoading.value = true
  getProfile()
})

// 獲取個人檔案
const getProfile = async () => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      const data = res.data
      const info = data.tenantAgentConfig
      info.avatar = info.avatar === null ? null : info.avatar + '?t=' + Math.floor(new Date().getTime() / 1000)
      profileInfo.value = {
        username: info.username,
        email: info.email,
        agentId: info.agentId,
        avatar: info.avatar,
        abilityNum: info.ability,
        helloMsgModel: info.helloMessageEnabled,
        helloMsg: info.helloMessage.length === 0 ? '歡迎諮詢，很高興為您服務' : res.data.helloMessage,
        roleName: data.roleName,
        deptName: data.departmentName,
        googleEmail: data.googleEmail
      }
      isPwdSet.value = data.passwordConfigured
      isTwoFactor.value = data.isBindTwoFactor
      isBindGoogle.value = !!data.googleEmail

      // 更新全局(頭像、名字)
      userInfo.userInfoDetails.tenantAgentStatus.avatar = info.avatar
      userInfo.userInfoDetails.tenantAgentStatus.username = info.username

      if (data) {
        progressLoading.value = false
      }
    }
  }
  await getProfileFn(onRes)
}

// 上傳頭像
const file = ref()
const uploadAvatar = (event) => {
  file.value = event.target.files[0]

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      const url = res.data

      const start = url.indexOf('/aican-im/')
      const end = url.indexOf('?')
      const newAvatar = url.substring(start, end)

      uploadImgFn(url, newAvatar)
    }
  }
  // getUploadUrlFn(file.value.name, onRes)
  getProfileUploadUrlFn(file.value.name, onRes)
}

// 前端上傳檔案
const uploadImgFn = (uploadUrl, newAvatar) => {
  const onRequest = ({ options }) => {
    options.headers['Content-Type'] = 'application/octet-stream'
  }
  const uploadImgOnRes = ({ response }) => {
    if (response.status === 200) {
      const formData = {
        avatar: newAvatar
      }
      editFn(formData) // 保存
    }
  }
  uploadImg(uploadUrl, file.value, onRequest, uploadImgOnRes)
}

// 修改名字
const editUserNameBlock = ref(false)
const editUserName = ref('')
const showEdit = (name) => {
  editUserNameBlock.value = true
  editUserName.value = name
}
const submitEditName = () => {
  const formData = {
    username: editUserName.value
  }
  editFn(formData)
}

// 修改個人檔案
const editProfile = () => {
  loading.value = true
  const formData = {
    oldPassword: oldPwd.value,
    password: newPwd.value,
    ability: profileInfo.value.abilityNum,
    helloMessageEnabled: profileInfo.value.helloMsgModel,
    helloMessage: profileInfo.value.helloMsg
  }
  editFn(formData)
}
const editFn = (formData) => {
  const editOnRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      // 修改姓名
      editUserNameBlock.value = false
      // 修改右邊資訊
      oldPwd.value = ''
      newPwd.value = ''
      checkPwd.value = ''
      getProfile()
      ElMessage.success({ message: '保存成功' })
    }
    loading.value = false
  }
  editProfileFn(formData, editOnRes)
}

// 二次驗證
const refreshQrCodeCheck = async () => {
  const onRes = ({ response }) => {
    const res = response._data

    if (res.code === 200) {
      isTwoFactor.value = res.data
    }
  }

  await checkTwoFactorFn(onRes)
}
provide('provideInfo', {
  refreshQrCodeCheck
})

// Google綁定
const googleBindOnSubmit = () => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      const authorizeAddr = res.data

      // 開啟google登入頁面
      const handleGoogleResponse = (googleRes) => {
        if (googleRes.code === 200) {
          getProfile()
          ElMessage.success({ message: '綁定成功' })
        } else {
          ElMessage.error({ message: googleRes.msg })
        }
      }
      openGoogleFn(authorizeAddr, handleGoogleResponse)
    }
  }
  googleBindFn(onRes)
}

</script>

<style lang="scss" scoped>
  #profile {
    display: flex;
    width: 100%; height: 100%;

    #profileCard {
      width: 450px;
      background: #E8E8E8;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      margin-right: 20px;

      .content {
        position: relative;
        padding: 20px 30px;

        .top {
          display: flex; flex-direction: column; align-items: center;

          .avatarBlock {
            position: relative;
            width: 90px; height: 90px;
            border-radius: 50%;

            .editBtn {
              width: 90px; height: 90px;
              border-radius: 50%;
              background: #474747a3;
              position: absolute; top: 0; left: 0; z-index: 2;
              font-size: 14px;
              color: #fff;
              display: none;
            }

            &:hover {
              .editBtn {
                display: block;
              }
            }

            .fileInput { // 上傳圖片
              position: absolute; top: 0; left: 0; z-index: 3;
              opacity: 0;
              width: 90px; height: 90px;
              border-radius: 50%;

              :deep(.v-input__prepend) {
                display: none;
              }
            }
          }
          :deep(.v-file-input input[type=file]) {
            height: 90px;
            cursor: pointer;
          }

          .nameBlock {
            position: relative;
            width: 100%;
            color: #fff; font-weight: bold;
            margin: 25px 0 40px 0;
            cursor: pointer;

            .name {
              max-width: 240px; height: 40px;
              position: relative; left: 50%; transform: translateX(-50%);
              padding: 0 20px;
              text-align: center;
              @include vars.textNowrap();
            }

            .fa-pen-to-square {
              display: none;
              position: absolute; top: 8px; right: 0;
              @include vars.fontStyle(16px, null, #ccc);
            }

            &:hover {
              .fa-pen-to-square {
                display: inline-block;
              }
            }

            .editUserNameBlock {
              display: flex;
              .editNameInput {
                width: 240px;
              }
              .inputBtn {
                background: #fff;
                padding: 0px 10px;
                border-radius: 8px;
                @include vars.fontStyle(14px, null, #222);

                .ok {
                  color: rgb(19, 199, 19);
                }
                .cancel {
                  color: rgb(201, 12, 53);
                }
              }
            }
          }

          :deep(.v-avatar) {
            border: 3px solid #fff;
          }
        }

        .card {
          padding: 24px 20px;
          border-radius: 10px;
          background: #fff;

          .editBlock {
            display: flex; align-items: center;
            .editMail {
              width: 88%;
              word-wrap: break-word;
            }
          }
        }
      }

      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 20px;
        background-color: #eeeeee;

        .levelBlock {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin-top: 20px;
        }

        .grayscale {
          filter: grayscale(1);
          transition: filter 0.3s ease; /* 添加动画效果 */
        }

        .clickable:hover {
          filter: grayscale(0%);
          opacity: 0.8; /* 可选，增加一点悬停时的视觉反馈 */
          cursor: pointer;
        }
      }

      .imgBg {
        width: 100%; height: 300px;
        position: absolute; top: 0; left: 0;

        img {
          width: 100%; height: 100%;
        }
        &::before {
          content: '';
          width: 100%; height: 230px;
          position: absolute; top: 0; left: 0;
          background: #222222c9;
        }
        &::after {
          content: '';
          width: 100%; height: 126px;
          position: absolute; bottom: -56px; left: 0;
          background: linear-gradient(180deg, rgb(26 26 26) -6%, rgb(232, 232, 232) 100%);
          opacity: 0.8;
        }
      }
    }

    #mainSettings {
      width: 100%; height: calc(100vh - 109px);
      position: relative;
      padding: 40px;

      .mainContent {
        height: calc(100vh - 150px);
        overflow: scroll;
      }
    }

    .saveSetBtn {
      position: absolute; bottom: 20px; right: 60px; z-index: 99;
    }
  }

  // 開關
  .switchBtn {
    display: flex; justify-content: space-between;
  }

  .subTitle {
    width: 70%;
    @include vars.fontStyle(14px, 1px, #929292);
    margin-top: 10px;
  }

  .plainInput { // 輸入框
    position: relative;
    :deep(.v-input__append) { // 眼睛icon
      position: absolute; top: 6px; right: 20px;
      font-size: 10px;
    }
    :deep(.v-field__input), :deep(.v-field__clearable) {
      padding-top: 4px;
    }
    :deep(.v-input__control) {
      border: 1px solid #c7c7c7;
      border-radius: 3px;
      padding: 5px 15px;
    }
  }
</style>
